<template>
  <dv-full-screen-container  class="daping_main">
    <div class="header" id="header">
      <div class="title">{{ userInfo.tnName }}学校奖卡统计</div>
      <div class="time">
        <img src="/aaw/img/time.png" width="28" height="28" alt="" /> 
        <div style="width: 230px; margin-left: 12px">
          {{ time }}
        </div>
        <div>
          {{ week }}
        </div>
      </div>
    </div>
    <div class="top">
      <el-row :gutter="35">
        <el-col :span="7">
          <top-left></top-left>
        </el-col>
        <el-col :span="10">
          <top-center></top-center>
        </el-col>
        <el-col :span="7">
          <top-right></top-right>
        </el-col>
      </el-row>
    </div>
    <div class="bottom">
      <el-row :gutter="35">
        <el-col :span="7">
          <bottom-left></bottom-left>
        </el-col>
        <el-col :span="17">
          <el-row :gutter="35">
            <el-col :span="8">
              <bottom-r-1></bottom-r-1>
            </el-col>
            <el-col :span="8">
              <bottom-r-2></bottom-r-2>
            </el-col>
            <el-col :span="8">
              <bottom-r-3></bottom-r-3>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </dv-full-screen-container>
</template>

<script>
import { mapGetters } from "vuex";
import TopLeft from "./components/topLeft.vue";
import TopCenter from "./components/topCenter";
import TopRight from "./components/topRight.vue";
import BottomLeft from "./components/bottomLeft.vue";
import BottomR1 from './components/bottomR1.vue';
import BottomR2 from './components/bottomR2.vue';
import BottomR3 from './components/bottomR3.vue';
export default {
  data() {
    return {
      myChart: null,
      detail: {},
      time: "",
      week: ''
    };
  },
  components: {
    TopLeft,
    TopCenter,
    TopRight,
    BottomLeft,
    BottomR1,
    BottomR2,
    BottomR3,
  },
  created() {
    this.setTime();
    setInterval(() => {
      this.setTime();
      
    }, 1000);
    // setTimeout(() => {
    //   this.requestFullScreen()
    // }, 1000)
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
     window.onresize = () => {
       console.log('22233')
       this.$router.go(-1)
     }
  },
  methods: {
//     requestFullScreen() {
//       if(screenfull.isEnabled) {
//         screenfull.request()
//       }
//   let element = document.documentElement;
//   // 判断浏览器设备类型
//   if (element.requestFullscreen) {
//     element.requestFullscreen();
//   } else if (element.mozRequestFullScreen) {   // 兼容火狐
//     element.mozRequestFullScreen();
//   } else if (element.webkitRequestFullscreen) {    // 兼容谷歌
//     element.webkitRequestFullscreen();
//   } else if (element.msRequestFullscreen) {   // 兼容IE
//     element.msRequestFullscreen();
//   }
// },
    setTime() {
      let date = new Date();
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.time = `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日 ${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:${
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
      }:${
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds()
      }`;
      this.week = weeks[date.getDay()]
    },
  },
  watch: {
    'document.documentElement'() {
      let element = document.documentElement;
  // 判断浏览器设备类型
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {   // 兼容火狐
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {    // 兼容谷歌
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {   // 兼容IE
    element.msRequestFullscreen();
  }
    }
  }
};
</script>

<style lang="scss">
.daping_main {
  width: 1920px;
  height: 1080px;
  background: url(/aaw/img/daping_bg.png) no-repeat;
  background-size: 100% 100%;
  color: #aaddff;
  .header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 73px;
    padding: 0 84px;
    background: url(/aaw/img/header_bg.png) no-repeat;
    background-size: 100% 100%;
    div {
    }
    .title {
      font-size: 32px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #5d9af5;
      line-height: 73px;
    }
    .time {
      position: fixed;
      top: 60px;
      right: 82px;
      display: flex;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #a9cdff;
      width: 340px;
    }
  }
  .top {
    margin: 35px 96px 24px 88px;
    height: 500px;
    .el-row {
      height: 100%;
      .el-col {
        height: 100%;
      }
    }
  }
  .bottom {
    margin: 0 96px 0 88px;
    height: 358px;
    .el-row {
      height: 100%;
      .el-col {
        height: 100%;
      }
    }
  }
}
</style>