<template>
  <div class="top_center">
    <div class="row">
      <div class="item">
        <span>今日奖卡数</span>
        <span class="count">
          <dv-digital-flop
            :config="{
              number: [data.today || 0],
              content: '{nt}',
              style: {
                fill: '#FFB956',
                fontSize: '28',
              },
            }"
            style="width: 120px;height: 40px"
          />
        </span>
      </div>
      <div class="item">
        <span>昨日奖卡数</span>
        <span class="count">
          <dv-digital-flop
            :config="{
              number: [data.yesterday || 0],
              content: '{nt}',
              style: {
                fill: '#FFB956',
                fontSize: '28',
              },
            }"
            style="width: 120px;height: 40px"
          />
        </span>
      </div>
    </div>
    <div class="row">
      <div class="item">
        <span>本周奖卡数</span>
        <span class="count">
          <dv-digital-flop
            :config="{
              number: [data.currentWeek || 0],
              content: '{nt}',
              style: {
                fill: '#FFB956',
                fontSize: '28',
              },
            }"
            style="width: 120px;height: 40px"
          />
        </span>
      </div>
      <div class="item">
        <span>上周奖卡数</span>
        <span class="count">
          <dv-digital-flop
            :config="{
              number: [data.lastWeek || 0],
              content: '{nt}',
              style: {
                fill: '#FFB956',
                fontSize: '28',
              },
            }"
            style="width: 120px;height: 40px"
          />
        </span>
      </div>
    </div>
    <div class="c_bottom">
      <div class="item">
        <div>本月奖卡数</div>
        <div class="colors">
          <dv-digital-flop
            :config="{
              number: [data.currentMonth || 0],
              content: '{nt}',
              style: {
                fill: '#FFB956',
                fontSize: '28',
              },
            }"
            style="width: 100%;height: 40px"
          />
        </div>
      </div>
      <div class="item">
        <div>上月奖卡数</div>
        <div class="colors">
          <dv-digital-flop
            :config="{
              number: [data.lastMonth || 0],
              content: '{nt}',
              style: {
                fill: '#FFB956',
                fontSize: '28',
              },
            }"
            style="width: 100%;height: 40px"
          />
        </div>
      </div>
      <div class="item">
        <div>本学期奖卡数</div>
        <div class="colors">
          <dv-digital-flop
            :config="{
              number: [data.all || 0],
              content: '{nt}',
              style: {
                fill: '#FFB956',
                fontSize: '28',
              },
            }"
            style="width: 100%;height: 40px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cardStatCount } from '@/http/daping'
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      data: {}
    };
  },
  components: {},
  created() {
    this.cardStatCount()
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
  },
  methods: {
    cardStatCount() {
      cardStatCount(this.userInfo.tnId).then(res => {
        this.data = res.data
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.top_center {
  width: 100%;
  height: 100%;
  padding: 60px 85px 0 85px;
  background: url(/aaw/img/top_center.png) no-repeat;
  background-size: 100% 100%;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      width: 287px;
      height: 127px;
      background: url(/aaw/img/top_center_item.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .count {
        // margin-left: 64px;
        font-size: 32px;
        color: #FFB956
      }
    }
  }
  .c_bottom {
    height: 136px;
    margin: 12px 24px;
    background: url(/aaw/img/top_center_item1.png) no-repeat;
    background-size: 100% 100%;
    font-size: 22px;
    color: #AADDFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .item {
      text-align: center;
      .colors {
        color: #FFB956;
        font-size: 26px;
        .company {
          color: #77C8FF
        }
      }
    }
  }
}
</style>