<template>
  <div class="top_left">
    <div class="title">
      <img src="/aaw/img/bottom_left_t.png" width="40" height="40" alt="" />
      本周奖卡趋势
    </div>
    <div id="chart2"></div>
  </div>
</template> 

<script>
import { cardStatTrends } from "@/http/daping";
import { mapGetters } from "vuex";

let echarts = require("echarts");
const colors = [
  "#248B97",
  "#E5AD55",
  "#0D4B95",
  "#1A72B9",
  "#3295C9",
  "#51B9DA",
  "#77CFD1",
  "#A1DEC8",
  "#C6EBD4",
  "#DCF3E5",
  "#F5FCF3",
  "#D5F1C1",
];
export default {
  data() {
    return {
      myChart: null,
      colors: colors,
      option: {
        tooltip: {
        trigger: 'axis'
    },
        legend: {
          bottom: "20",
          textStyle: {
            color: "#83B5FB",
          },
        },
        grid: {
          left: "10",
          right: "20",
          top: "10",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#029AFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              show: true,
              color: "#029AFF",
              fontSize: 12,
            },
            boundaryGap: false,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(24, 204, 239, .1)",
              },
            },
          },
        ],
        series: [
        ],
      },
    };
  },
  components: {},
  created() {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById("chart2"));
    this.init();
  },
  methods: {
    init() {
      cardStatTrends(this.userInfo.tnId).then((res) => {
        res.data = res.data.reverse();

        this.option.xAxis[0].data = res.data[0].count.map((item) => {
          return item.date.substr(5);
        });
        let serves = [];
        res.data.forEach((v, k) => {
          let data = v.count.map((item) => {
            return item.count;
          });

          serves.push({
            name: v.name,
            type: "line",
            areaStyle: {
              color: "rgba(24,204,239,0.2)",
            },
            lineStyle: {
              color: colors[k],
            },
            itemStyle: {
              color: colors[k],
            },
            smooth: true,
            data: data,
          });
        });
        this.option.series = serves
        this.myChart.setOption(this.option);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_left {
  width: 100%;
  height: 100%;
  padding: 26px 18px;
  background: url(/aaw/img/top_left.png) no-repeat;
  background-size: 100% 100%;
  .title {
    height: 40px;
    font-size: 22px;
    line-height: 40px;
  }
  .grades {
    .grade {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      .left {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
  #chart2 {
    width: 100%;
    height: 100%;
  }
}
</style>