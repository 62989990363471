var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top_left"},[_vm._m(0),_c('div',{attrs:{"id":"chart1"}}),_c('div',{staticClass:"grades"},[_c('el-row',{attrs:{"gutter":17}},_vm._l((_vm.grades),function(v,k){return _c('el-col',{key:k,attrs:{"span":4}},[_c('div',{staticClass:"grade"},[_c('div',{staticClass:"left",style:('background-color:' + _vm.colors[k])}),_c('div',[_vm._v(_vm._s(v))])])])}),1)],1),_c('div',{staticClass:"count"},[_c('div',{staticClass:"item"},[_c('div',[_vm._v("学生总人数")]),_c('div',{staticClass:"colors"},[_c('dv-digital-flop',{staticStyle:{"height":"30px"},attrs:{"config":{
            number: [_vm.data.totalNum || 0],
            content: '{nt}',
            style: {
              fill: '#FFB956',
              fontSize: '20',
            },
          }}})],1)]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("教师总人数")]),_c('div',{staticClass:"colors"},[_c('dv-digital-flop',{staticStyle:{"height":"30px"},attrs:{"config":{
            number: [_vm.data.teacherNum || 0],
            content: '{nt}个',
            style: {
              fill: '#FFB956',
              fontSize: '20',
            },
          }}})],1)]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("班级总人数")]),_c('div',{staticClass:"colors"},[_c('dv-digital-flop',{staticStyle:{"height":"30px"},attrs:{"config":{
            number: [_vm.data.classNum || 0],
            content: '{nt}个',
            style: {
              fill: '#FFB956',
              fontSize: '20',
            },
          }}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":"/aaw/img/top_left_top.png","width":"40","height":"40","alt":""}}),_vm._v(" 各年级学生人数 ")])}]

export { render, staticRenderFns }