<template>
  <div class="top_left">
    <div class="title">
      <img src="/aaw/img/top_left_top.png" width="40" height="40" alt="" />
      各年级学生人数
    </div>
    <div id="chart1"></div>
    <div class="grades">
      <el-row :gutter="17">
        <el-col :span="4" v-for="(v, k) in grades" :key="k">
          <div class="grade">
            <div class="left" :style="'background-color:' + colors[k]"></div>
            <div>{{ v }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="count">
      <div class="item">
        <div>学生总人数</div>
        <div class="colors">
          <dv-digital-flop
            :config="{
              number: [data.totalNum || 0],
              content: '{nt}',
              style: {
                fill: '#FFB956',
                fontSize: '20',
              },
            }"
            style="height: 30px"
          />
        </div>
      </div>
      <div class="item">
        <div>教师总人数</div>
        <div class="colors">
          <dv-digital-flop
            :config="{
              number: [data.teacherNum || 0],
              content: '{nt}个',
              style: {
                fill: '#FFB956',
                fontSize: '20',
              },
            }"
            style="height: 30px"
          />
        </div>
      </div>
      <div class="item">
        <div>班级总人数</div>
        <div class="colors">
          <dv-digital-flop
            :config="{
              number: [data.classNum || 0],
              content: '{nt}个',
              style: {
                fill: '#FFB956',
                fontSize: '20',
              },
            }"
            style="height: 30px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allStudentStat } from "@/http/daping";
import { mapGetters } from "vuex";
let echarts = require("echarts");
const colors = [
  "#248B97",
  "#E5AD55",
  "#0D4B95",
  "#1A72B9",
  "#3295C9",
  "#51B9DA",
  "#77CFD1",
  "#A1DEC8",
  "#C6EBD4",
  "#DCF3E5",
  "#F5FCF3",
  "#D5F1C1",
];
export default {
  data() {
    return {
      myChart: null,
      time: "",
      colors: colors,
      data: {},
      grades: [
      ],
      option: {
        tooltip: {
          trigger: "item",
          formatter: '{b} : {c} ({d}%)'
        },
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "70%"],
            avoidLabelOverlap: false,
            label: {
              fontSize: 12,
              color: "#88DDFD",
            },
            labelLine: {
              lineStyle: {
                color: "#88DDFD",
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  components: {},
  created() {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById("chart1"));
    this.init();
  },
  methods: {
    init() {
      allStudentStat(this.userInfo.tnId).then(res => {
        console.log(res)
        this.data = res.retContent
        this.grades = res.retContent.gradeStudentNum.map(item => {
          return item.gradeName
        })
        this.option.series[0].data = res.retContent.gradeStudentNum.map((item, k) => {
          let percentage = (item.studentCount / res.retContent.totalNum * 100).toFixed(2) + '%'

          return {
            value: item.studentCount,
            name: item.gradeName + ':  ' + percentage,
            itemStyle: {
              color: colors[k],
            },
          }
        })
        this.myChart.setOption(this.option);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.top_left {
  width: 100%;
  height: 100%;
  padding: 26px 18px;
  background: url(/aaw/img/top_left.png) no-repeat;
  background-size: 100% 100%;
  .title {
    height: 40px;
    font-size: 22px;
    line-height: 40px;
  }
  .grades {
    .grade {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      .left {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
  #chart1 {
    width: 100%;
    height: 280px;
  }
  .count {
    margin-top: 12px;
    height: 96px;
    background: url(/aaw/img/top_left_b.png) no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #aaddff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
    .item {
      text-align: center;
      .colors {
        color: #ffb956;
        font-size: 20px;
        display: flex;
        justify-content: center;
        .company {
          color: #77c8ff;
        }
      }
    }
  }
}
</style>