var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top_center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("今日奖卡数")]),_c('span',{staticClass:"count"},[_c('dv-digital-flop',{staticStyle:{"width":"120px","height":"40px"},attrs:{"config":{
            number: [_vm.data.today || 0],
            content: '{nt}',
            style: {
              fill: '#FFB956',
              fontSize: '28',
            },
          }}})],1)]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("昨日奖卡数")]),_c('span',{staticClass:"count"},[_c('dv-digital-flop',{staticStyle:{"width":"120px","height":"40px"},attrs:{"config":{
            number: [_vm.data.yesterday || 0],
            content: '{nt}',
            style: {
              fill: '#FFB956',
              fontSize: '28',
            },
          }}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("本周奖卡数")]),_c('span',{staticClass:"count"},[_c('dv-digital-flop',{staticStyle:{"width":"120px","height":"40px"},attrs:{"config":{
            number: [_vm.data.currentWeek || 0],
            content: '{nt}',
            style: {
              fill: '#FFB956',
              fontSize: '28',
            },
          }}})],1)]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("上周奖卡数")]),_c('span',{staticClass:"count"},[_c('dv-digital-flop',{staticStyle:{"width":"120px","height":"40px"},attrs:{"config":{
            number: [_vm.data.lastWeek || 0],
            content: '{nt}',
            style: {
              fill: '#FFB956',
              fontSize: '28',
            },
          }}})],1)])]),_c('div',{staticClass:"c_bottom"},[_c('div',{staticClass:"item"},[_c('div',[_vm._v("本月奖卡数")]),_c('div',{staticClass:"colors"},[_c('dv-digital-flop',{staticStyle:{"width":"100%","height":"40px"},attrs:{"config":{
            number: [_vm.data.currentMonth || 0],
            content: '{nt}',
            style: {
              fill: '#FFB956',
              fontSize: '28',
            },
          }}})],1)]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("上月奖卡数")]),_c('div',{staticClass:"colors"},[_c('dv-digital-flop',{staticStyle:{"width":"100%","height":"40px"},attrs:{"config":{
            number: [_vm.data.lastMonth || 0],
            content: '{nt}',
            style: {
              fill: '#FFB956',
              fontSize: '28',
            },
          }}})],1)]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("本学期奖卡数")]),_c('div',{staticClass:"colors"},[_c('dv-digital-flop',{staticStyle:{"width":"100%","height":"40px"},attrs:{"config":{
            number: [_vm.data.all || 0],
            content: '{nt}',
            style: {
              fill: '#FFB956',
              fontSize: '28',
            },
          }}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }