<template>
  <div class="top_right">
    <div class="title">
      <img src="/aaw/img/bottom_r_1.png" width="40" height="40" alt=""> 今日老师发卡情况
    </div>
    <dv-scroll-board
      v-if="config.data.length > 0"
      :config="config"
      style="width: 100%; height: 260px; margin-top: 12px"
    />
    
  </div>
</template>

<script>
import { cardStatTeacher } from '@/http/daping'
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      config: {
        data: [],
        columnWidth: [50], 
        rowNum: 6,
        align: ["left"],
      }
    };
  },
  components: {},
  created() {
    this.cardStatTeacher()
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    
  },
  methods: {
    cardStatTeacher() {
      let rows = [
        '<img src="/aaw/img/jin.png" width="26" height="26" alt="">',
        '<img src="/aaw/img/yin.png" width="26" height="26" alt="">',
        '<img src="/aaw/img/jin.png" width="26" height="26" alt="">'
      ]
      cardStatTeacher(this.userInfo.tnId).then(res => {
        this.config.data = res.data.map((item, k) => {
          return [
            rows[k] || '',
            item.count + '张',
            item.teacherName,
            item.courseName,
          ]
        });
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.top_right {
  width: 100%;
  height: 100%;
  padding: 26px 18px;
  background: url(/aaw/img/top_left.png) no-repeat;
  background-size: 100% 100%;
  .title {
    height: 40px;
    font-size: 22px;
    line-height: 40px;
  }
}
</style>