<template>
  <div class="top_right">
    <div class="title">
      <img src="/aaw/img/top_r.png" width="40" height="40" alt="" /> 学生获卡记录
    </div>
    <dv-scroll-board
      v-if="config.data.length > 0"
      :config="config"
      style="width: 100%; height: 400px; margin-top: 12px"
    />
  </div>
</template>

<script>
import { cardStatHistory } from '@/http/daping'
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      config: {
        data: [],
        columnWidth: ["300"],
        rowNum: 9,
        align: ["left"],
      },
    };
  },
  components: {},
  created() {
    this.cardStatHistory()
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {},
  methods: {
    cardStatHistory() {
      cardStatHistory(this.userInfo.tnId).then(res => {
        this.config.data = res.data.map(item => {
          return [
            `${item.studentName}(${item.className})获得${item.teacherName}老师的【${item.name}】奖卡`,
            this.$cftY(item.receivedAt)
          ]
        })
      })
    }
  },
};
</script>

<style lang="scss">
.top_right {
  width: 100%;
  height: 100%;
  padding: 26px 18px;
  background: url(/aaw/img/top_left.png) no-repeat;
  background-size: 100% 100%;
  .el-table__row {
    background-color: #060f30 !important;
    color: #68a0ee !important;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #68a0ee !important;
  }
  .title {
    height: 40px;
    font-size: 22px;
    line-height: 40px;
  }
}
</style>